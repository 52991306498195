<template>
  <div>
  {{name}}
  </div>
</template>

<script>

export default {
  name: 'HomeView',
 
}

</script>
